import { useContext } from "react";
import { Navigate } from "react-router-dom";
import GoogleLoginButton from "../../Components/GoogleLoginButton/GoogleLoginButton";
import { UserContext } from "../../context/UserContext";

const Login = () => {
  const { user } = useContext(UserContext);
  if (user) {
    return <Navigate to="/list" />;
  }
  return (
    <div
      style={{
        padding: "30px",
        textAlign: "center",
      }}
    >
      <h1>Login to continue</h1>
      <GoogleLoginButton />
    </div>
  );
};

export default Login;
