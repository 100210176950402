import { TokenResponse } from "@react-oauth/google";
import { createContext } from "react";

interface UserContextType {
  user: TokenResponse | null;
  setUser: React.Dispatch<React.SetStateAction<TokenResponse | null>>;
}

export const UserContext = createContext<UserContextType>(
  {} as UserContextType
);
