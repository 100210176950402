import lines from "./lines.svg";
import logo from "./Bitnoise_logo.svg";

import styles from "./Details.module.css";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { spreadsheetId } from "../../config/config";
import Loading from "../../Components/Loading/Loading";
import { sheetToEmployee } from "./utils";
import clsx from "clsx";
import ExperienceDetail from "../../Components/Details/ExperienceDetail/ExperienceDetail";
import EducationDetail from "../../Components/Details/EducationDetail/EducationDetail";
import ProjectsDetail from "../../Components/Details/ProjectsDetail/ProjectsDetail";
import { message } from "antd";
import { UserContext } from "../../context/UserContext";
import ListItem from "../../Components/ListItem/ListItem";

export interface ProjectInfo {
  name: string;
  position: string;
  technologies: string;
  length: string;
}

export interface CompanyInfo {
  name: string;
  position: string;
  years: string;
  descriptions: string[];
}

export interface EducationInfo {
  name: string;
  position: string;
  years: string;
}

export interface EmployeeInfo {
  name: string;
  surname: string;
  position: string;
  experience: string;
  description: string;
  technologies: string[];
  additionalTechnologies: string[];
  recentProjects: ProjectInfo[];
  career: CompanyInfo[];
  education: EducationInfo[];
  languages: string[];
  certificates: string[];
}

const Details = () => {
  const [info, setInfo] = useState<EmployeeInfo>();
  const [error, setError] = useState<string>();
  const { setUser } = useContext(UserContext);
  const { id } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    gapi.client.sheets.spreadsheets
      .get({
        spreadsheetId: spreadsheetId,
      })
      .then((res) => {
        const sheet = res.result.sheets?.find(({ properties }) =>
          properties?.title?.includes(`${id}:`)
        );
        if (!sheet?.properties?.title) {
          setError("No sheet found for id");
          return;
        }

        gapi.client.sheets.spreadsheets.values
          .get({
            spreadsheetId: spreadsheetId,
            majorDimension: "COLUMNS",
            range: sheet.properties.title,
          })
          .then((res) => {
            const data = res.result.values?.map((value) => value.slice(2));
            if (!data) {
              setError("No data");
              return;
            }
            setInfo(sheetToEmployee(data));
            setTimeout(() => window.print(), 100);
          });
      })
      .catch((e) => {
        message.error(e.result.error.message);
        setUser(null);
      });
  }, [id, setUser]);
  useEffect(() => {
    if (window.matchMedia) {
      var mediaQueryList = window.matchMedia("print");
      mediaQueryList.addEventListener("change", (mql) => {
        if (!mql.matches) {
          navigate("/list");
        }
      });
    }
  });

  if (error) {
    return <h1>{error}</h1>;
  }

  if (!info) {
    return <Loading text="Downloading employee data" />;
  }

  const {
    name,
    surname,
    position,
    // experience,
    description,
    technologies,
    additionalTechnologies,
    recentProjects,
    career,
    education,
    languages,
    certificates,
  } = info;

  return (
    <>
      <div className={styles.pageInfo}>
        <h1>Printing</h1>
      </div>

      <div className={styles.page}>
        <img className={styles.logo} src={logo} alt="Bitnoise" />
        <h1 className={styles.position}>{position}</h1>
        <section className={styles.section}>
          <h2 className={clsx(styles.withDash, styles.header)}>
            Personal data
          </h2>
          <h3 className={styles.name}>
            Name: {name} {surname}
          </h3>
          <p className={styles.description}>{description}</p>
        </section>

        <section className={styles.section}>
          <h2 className={clsx(styles.withDash, styles.header)}>
            Skills profile
          </h2>
          <div className={styles.skillsProfileWrapper}>
            {technologies && (
              <div className={styles.skills}>
                <h3 className={styles.withDash}>Primary skills</h3>
                <div className={styles.skillsWrapper}>
                  <ul className={styles.list}>
                    {technologies.map((tech, i) => (
                      <ListItem key={i}>{tech}</ListItem>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {additionalTechnologies && (
              <div className={styles.skills}>
                <h3 className={styles.withDash}>Secondary skills</h3>
                <div className={styles.skillsWrapper}>
                  <ul className={styles.list}>
                    {additionalTechnologies.map((tech, i) => (
                      <ListItem key={i}>{tech}</ListItem>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            {languages && (
              <div className={styles.languages}>
                <h3 className={styles.withDash}>Languages</h3>
                <ul className={styles.list}>
                  {languages.map((lang, i) => lang && <ListItem key={i}>{lang}</ListItem>)}
                </ul>
              </div>
            )}
            {certificates && (
              <div className={styles.certificates}>
                <h3 className={styles.withDash}>Certificates</h3>
                <ul className={styles.list}>
                  {certificates.map(
                    (cert, i) => cert && <ListItem key={i}>{cert}</ListItem>
                  )}
                </ul>
              </div>
            )}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={clsx(styles.withDash, styles.header)}>
            Recent projects
          </h2>

          <div>
            {recentProjects.map((project, i) => (
              <ProjectsDetail key={i} project={project} />
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={clsx(styles.withDash, styles.header)}>
            Job Experience
          </h2>

          <div>
            {career.map((company, i) => (
              <ExperienceDetail key={i} company={company} />
            ))}
          </div>
        </section>

        <section className={styles.section}>
          <h2 className={clsx(styles.withDash, styles.header)}>Education</h2>

          <div>
            {education.map((school, i) => (
              <EducationDetail key={i} school={school} />
            ))}
          </div>
        </section>
        <div className={styles.imageWrapper}>
          <img src={lines} className={styles.linesShape} alt="lines" />
        </div>
      </div>
    </>
  );
};

export default Details;
