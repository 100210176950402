import { FC } from "react";
import { EducationInfo } from "../../../Pages/Details/Detais";
import ListItem from "../../ListItem/ListItem";
import styles from "../Details.module.css";

interface EducationDetailProps {
  school: EducationInfo;
}

const EducationDetail: FC<EducationDetailProps> = ({ school }) => {
  const { name, position, years } = school;
  return (
    <div className={styles.experience}>
      <div className={styles.years}>{years}</div>
      <div>
        <h4>{name}</h4>
        <div>
          <ul>
            <ListItem>{position}</ListItem>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EducationDetail;
