import { Spin } from "antd";
import { FC } from "react";

interface LoadingProps {
  text?: string;
}

const Loading: FC<LoadingProps> = ({ text }) => {
  return (
    <div
      style={{
        padding: "30px",
        textAlign: "center",
      }}
    >
      {text && <p>{text}</p>}
      <Spin size="large" />
    </div>
  );
};

export default Loading;
