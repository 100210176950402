import { FC, ReactNode } from "react";

interface ListItemProps {
  children: string;
}
const ListItem: FC<ListItemProps> = ({ children }) => {
  if (!children?.trim()) {
    return null;
  }
  return <li>{children}</li>;
};

export default ListItem;
