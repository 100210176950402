import { FC } from "react";
import { ProjectInfo } from "../../../Pages/Details/Detais";
import ListItem from "../../ListItem/ListItem";
import styles from "../Details.module.css";

interface ProjectsDetailProps {
  project: ProjectInfo;
}

const ProjectsDetail: FC<ProjectsDetailProps> = ({ project }) => {
  const { name, position, length, technologies } = project;
  return (
    <div className={styles.experience}>
      <div className={styles.years}>{length}</div>
      <div>
        <h4>{name}</h4>
        <div>
          <ul>
            <ListItem>{position}</ListItem>
            <ListItem>{technologies}</ListItem>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProjectsDetail;
