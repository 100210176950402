import { FC } from "react";
import { CompanyInfo } from "../../../Pages/Details/Detais";
import ListItem from "../../ListItem/ListItem";
import styles from "../Details.module.css";

interface ExperienceDetailProps {
  company: CompanyInfo;
}

const ExperienceDetail: FC<ExperienceDetailProps> = ({ company }) => {
  const { name, position, years, descriptions } = company;
  return (
    <div className={styles.experience}>
      <div className={styles.years}>{years}</div>
      <div>
        <h4>{position}</h4>
        <p>Company: {name}</p>
        <div>
          <ul>
            {descriptions.map((desc, i) => (
              <ListItem key={i}>{desc}</ListItem>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ExperienceDetail;
