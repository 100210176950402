import { GoogleOAuthProvider, TokenResponse } from "@react-oauth/google";
import { useEffect, useState } from "react";
import { googleClientId } from "./config/config";
import { UserContext } from "./context/UserContext";
import Loading from "./Components/Loading/Loading";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import List from "./Pages/List/List";
import RequireAuth from "./Components/RequireAuth/RequireAuth";
import Details from "./Pages/Details/Detais";

function App() {
  const [user, setUser] = useState<TokenResponse | null>(null);
  const [gapiLoaded, setGapiLoaded] = useState(false);
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/client.js";

    script.onload = () => {
      gapi.load("client", () => {
        gapi.client.init({
          clientId: googleClientId,
          discoveryDocs: [
            "https://sheets.googleapis.com/$discovery/rest?version=v4",
          ],
        });
        setGapiLoaded(true);
      });
    };

    document.body.appendChild(script);
  }, []);

  if (!gapiLoaded) return <Loading text="Loading google api client..." />;

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <BrowserRouter>
          <Routes>
            <Route path="/list" element={<RequireAuth />}>
              <Route path=":id" element={<Details />} />

              <Route path="" element={<List />} />
            </Route>
            <Route path="/" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </UserContext.Provider>
  );
}

export default App;
