import GoogleOutlined from "@ant-design/icons/lib/icons/GoogleOutlined";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, message } from "antd";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const GoogleLoginButton = () => {
  const { setUser } = useContext(UserContext);
  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setTimeout(() => {
        message.warn("Login timeout");
        setUser(null);
      }, codeResponse.expires_in * 1000);
      setUser(codeResponse);
    },
    scope: "https://www.googleapis.com/auth/spreadsheets.readonly",
  });
  // login();
  return (
    <Button onClick={() => login()}>
      <GoogleOutlined /> Login with google
    </Button>
  );
};
export default GoogleLoginButton;
