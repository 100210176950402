import { EmployeeInfo } from "./Detais";

export const sheetToEmployee = (data: string[][]): EmployeeInfo => ({
  name: data[0][0],
  surname: data[1][0],
  position: data[2][0],
  experience: data[3][0],
  description: data[4][0],
  technologies: data[5][0]?.split("\n"),
  additionalTechnologies: data[6][0]?.split("\n"),
  recentProjects: data[7]?.map((project: string) => {
    const [name, position, technologies, length] = project.split("\n");
    return { name, position, technologies, length };
  }),
  career: data[8]?.map((company: string) => {
    const [name, position, years, ...descriptions] = company.split("\n");
    return { name, position, years, descriptions };
  }),
  education: data[9]?.map((school: string) => {
    const [name, position, years] = school.split("\n");
    return { name, position, years };
  }),
  languages: data[10][0]?.split("\n"),
  certificates: data[11][0]?.split("\n"),
});
