import { Button, Table } from "antd";
import type { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { spreadsheetId } from "../../config/config";
import Loading from "../Loading/Loading";

interface EmployeeBasicInfo {
  id: number;
  name: string;
  surname: string;
}

const columns: ColumnsType<EmployeeBasicInfo> = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Imie",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Nazwisko",
    dataIndex: "surname",
    key: "surname",
  },
  {
    title: "Action",
    key: "action",
    render: (_, record) => (
      <Link to={`./${record.id}`}>
        <Button> otwórz {record.id} </Button>
      </Link>
    ),
  },
];

const ListOfEmployees = () => {
  const [list, setList] = useState<EmployeeBasicInfo[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    gapi.client.sheets.spreadsheets.values
      .get({
        spreadsheetId: spreadsheetId,
        range: "Ludzie!A:C",
      })
      .then((response) => {
        const employees = response.result.values?.slice(1);
        if (employees)
          setList(
            employees.map(
              ([id, name, surname]) =>
                ({
                  id: Number(id),
                  name,
                  surname,
                } as EmployeeBasicInfo)
            )
          );
      })
      .catch((e) => {
        setError(e.result.error.message);
      });
  }, []);
  if (error)
    return (
      <div
        style={{
          padding: "30px",
          textAlign: "center",
        }}
      >
        <h1> {error}</h1>
      </div>
    );

  if (!list) return <Loading text="Loading list" />;

  return (
    <Table
      rowKey="id"
      columns={columns}
      dataSource={list}
      pagination={false}
    ></Table>
  );
};

export default ListOfEmployees;
